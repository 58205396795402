<template>
  <div class="login-block flex-center">
    <img class="logo" src="../../assets/clab-logo.png" alt="clab-logo" />
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
      label-position="top"
    >
      <el-form-item prop="username">
        <el-input
          v-model="loginForm.username"
          prefix-icon="el-icon-user-solid"
          autocomplete="on"
          @keyup.enter.native="handleLogin"
        ></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          v-model="loginForm.password"
          type="password"
          prefix-icon="el-icon-lock"
          show-password
          autocomplete="on"
          @keyup.enter.native="handleLogin"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          :loading="loading"
          type="success"
          class="w-100"
          @click="handleLogin"
          >登入
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
      },
      loginRules: {
        username: [
          {
            required: true,
            trigger: "blur",
            message: "請輸入信箱",
          },
        ],
        password: [
          {
            required: true,
            trigger: "blur",
            message: "請輸入密碼",
          },
        ],
      },
      loading: false,
    };
  },
  methods: {
    handleLogin() {
      this.loading = true;
      this.$store
        .dispatch("user/login", this.loginForm)
        .then(async () => {
          await this.$router.push({ path: "/" });
        })
        .catch((error) => console.error(error))
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.login-block {
  height: 100vh;
  background-color: var(--login-bg);
  flex-direction: column;

  .logo {
    width: 280px;
    margin-bottom: 28px;
  }

  .login-form {
    width: 300px;
  }
}
</style>
